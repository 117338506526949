import { isValid, parse, format } from "date-fns";
import { isNil, toNumber } from "lodash";
import { DATE_TIME_PATTERN } from "./Constants";

export function createStringDateAsUTC(date) {
  let dateUTC = new Date(date);

  //If dateUTC is not valid by default Date constructor, parse it
  if (!isValid(dateUTC)) {
    dateUTC = parse(date, DATE_TIME_PATTERN, new Date());
  }

  return new Date(
    Date.UTC(
      dateUTC.getFullYear(),
      dateUTC.getMonth(),
      dateUTC.getDate(),
      dateUTC.getHours(),
      dateUTC.getMinutes(),
      dateUTC.getSeconds()
    )
  );
}

/**
 * Transform local Date to UTC time
 * @param {Date} localDate
 * @returns
 */
export function localDateToUtc(localDate) {
  if (isNil(localDate)) {
    return localDate;
  }

  return new Date(localDate.toUTCString().substring(0, 25));
}

/**
 * Transform local Date string to UTC String date
 * @param {String} localDateString
 * @param {String} formatToApply
 * @returns
 */
export function localDateStringToUtcString(localDateString, formatToApply) {
  if (isNil(localDateString) || isNil(formatToApply)) {
    return localDateString;
  }

  const localDate = parse(localDateString, formatToApply, new Date());
  if (isValid(localDate)) {
    const utcDate = new Date(localDate.toUTCString().substring(0, 25));
    return format(utcDate, formatToApply);
  } else {
    return localDateString;
  }
}

/**
 * Transform UTC Date to Local date
 * @param {Date} utcDate
 * @returns Date
 */
export function utcDateToLocal(utcDate) {
  if (isNil(utcDate)) {
    return utcDate;
  }

  const result = new Date(
    utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000
  );
  return result;
}

/**
 * Transform UTC String Date to Local String Date
 * @param {String} utcStringDate
 * @param {String} formatToApply
 * @returns String
 */
export function utcStringDateToStringLocal(utcStringDate, formatToApply) {
  if (isNil(utcStringDate) || isNil(formatToApply)) {
    return utcStringDate;
  }  
  const utcDateFormat = new Date(utcStringDate+'T00:00:00Z');
  const utcDate = parse(utcDateFormat.toString(), formatToApply, new Date());
 
  if (isValid(utcDate)) {
    const result = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000
    ); 
    return format(result, formatToApply);
  } else {
    return utcStringDate;
  }
}

/**
 * Get UTC Tooltip text
 * Example: UTC (-3), UTC (+3), UTC when its UTC 0
 * @returns string
 */
export function getUTCTooltip() {
  const offset = getTimeOffsetInHours(); //get offset in hours
  if (offset != null) {
    return toNumber(offset) === 0
      ? `UTC`
      : toNumber(offset) < 0
      ? `UTC (${Number(offset).toFixed(2)})`
      : `UTC (+${Number(offset).toFixed(2)})`;
  } else {
    return "";
  }
}

export function getTimeOffsetInHours() {
  const timeOffset = new Date().getTimezoneOffset();
  const mh = minutesToHours(timeOffset);
  return Number(mh).toFixed(2);
}

export function minutesToHours(min) {
  const totalMinutes = min * -1;
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  return `${hours}.${Math.abs(minutes)}`;
}

export function safeFormattingDate(baseFormat, desireFormat, value) {
  let result = null;
  try {
    result = format(
      parse(
        utcStringDateToStringLocal(value, baseFormat),
        baseFormat,
        new Date()
      ),
      desireFormat
    );
  } catch (e) {
    result = null;
  }
  return result;
}

export function isDateTimeString(value, format) {
  try {
    const dateParsed = parse(value, format, new Date());
    return isValid(dateParsed);
  } catch (_) {
    return false;
  }
}
